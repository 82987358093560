import React from "react";
import { Container } from "semantic-ui-react";

import LayoutInner from "../../components/layout/layout-inner";
import SEO from "../../components/seo/seo";
import BodyText from "../../components/body-text/body-text";
import SecondaryNavBar from "../../components/secondary-nav-bar/secondary-nav-bar";
import { SITE_HOST } from "../../constants";

const Page = () => (
  <LayoutInner>
    <SEO title="RTV Terms and Conditions" keywords={[`rocksteady`]} />
    <SecondaryNavBar
      product="generic"
      title="Terms Conditions"
      titlePath="/terms-conditions/"
      titleActive={true}
      links={[]}
    />
    <Container>
      <BodyText>
        <h1>
          <strong>Rocksteady TV Agreement</strong>
        </h1>
        <p>
          I refer to the audio and/or audio-visual recordings (the Recordings)
          which will include the musical performance of your child&nbsp;(the
          Band Member), and which have been made or are proposed to be made as
          part of the Band Member’s participation in the Rocksteady Music
          School, operated by Rocksteady Music School Limited (Rocksteady).
        </p>
        <p>
          I am the parent or guardian of the Band Member and I understand that
          Rocksteady needs to clear the rights to the Band Member’s performance
          for the purpose of potentially publishing the Recordings on the
          Rocksteady website ( {`www.${SITE_HOST}`} ) and/or on
          Rocksteady’s social media pages as part of a “Rocksteady TV or RTV”
          feature.&nbsp;I understand that this includes a band photo featuring
          my child for the purpose of promoting Rocksteady TV on Rocksteady’s
          social media pages as part of a Rocksteady TV or RTV feature.
        </p>
        <p>
          In consideration for the opportunity described above (which, together
          with the provision of his/her musical performance and the entering
          into of this agreement, I agree is to the Band Member’s benefit){" "}
          <strong>I agree as follows</strong> on the Band Member’s behalf:
        </p>
        <ul>
          <li>1. The Band Member consents to the making of the Recordings.</li>
          <li>
            2. The Band Member irrevocably grants and assigns to Rocksteady all
            his/her right, title and interest in his/her performance and the
            Recordings including all performer’s property rights (as defined in
            the Copyright Designs and Patents Act 1988 – the Act) to the extent
            such rights are assignable, and (to the extent owned by the Band
            Member) the worldwide copyright and all other rights in the
            Recordings in all media now or hereafter known for the full period
            of such rights and all reversions and renewals.
          </li>
          <li>
            3. The Band Member gives and confirms to Rocksteady all consents
            required under the Act and equivalent legislation now or in the
            future in force in all other jurisdictions for the exploitation of
            the Recordings by Rocksteady in all media now or hereafter known
            worldwide in perpetuity without further reference to the Band
            Member.
          </li>
          <li>
            4. The Band Member waives all so-called “moral rights” in the
            Recordings that he/she may have under the Act and under equivalent
            legislation now and in the future in force in any part of the world.
          </li>
        </ul>
        <p>
          <strong>I further undertake and agree on my own behalf:</strong>
        </p>
        <ul>
          <li>
            5. Neither Rocksteady nor any other party shall have any obligation
            to give the Band Member personal credit in respect of the use of the
            Recordings.
          </li>
          <li>
            6. Rocksteady is under no obligation to release or exploit the
            Recordings in any way.
          </li>
          <li>
            7. No further payment or other form of compensation will be made to
            the Band Member by Rocksteady or any other party in respect of
            his/her participation in the Recordings and/or their use as
            described above.
          </li>
          <li>
            8. Rocksteady is free to assign, sub-license or otherwise deal with
            the Recordings or any of its rights hereunder as necessary to
            exploit the Recordings as described above.
          </li>
          <li>
            9. I shall, on the Band Member’s behalf and at Rocksteady’s expense,
            sign any further documents that may be necessary to confirm or
            secure to Rocksteady the rights expressed to be granted in this
            agreement.
          </li>
        </ul>
        <p>
          This agreement is governed by and will be construed in accordance with
          the laws of England and Wales and the parties agree to submit to the
          jurisdiction of the English courts as regard any claim, dispute or
          other matter arising under or in relation to this agreement.
        </p>
      </BodyText>
    </Container>
  </LayoutInner>
);

export default Page;
